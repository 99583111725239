const BaseURL = '/api';

async function getProjects(){
    let url = '/projects';
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const projectsJson = await response.json();
        return projectsJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getProject(id){
    let url = "/projects/" + id;
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const projectJson = await response.json();
        return projectJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getAcademia(){
    let url = "/academia";
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const projectsJson = await response.json();
        return projectsJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getPartners(){
    let url = '/partners';
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const partnersJson = await response.json();
        return partnersJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getPartner(id){
    let url = "/partners/" + id;
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const partnerJson = await response.json();
        return partnerJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getPublications(){
    let url = '/publications';
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const publicationsJson = await response.json();
        return publicationsJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getContacts(){
    let url = '/contacts';
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const contactsJson = await response.json();
        return contactsJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function getHomepage(){
    let url = '/homepage';
    const response = await fetch(BaseURL + url);
    if(response.ok){
        const homepageJson = await response.json();
        return homepageJson;
    }
    else {
        let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "errore" : response.statusText};
        throw err;  // An object with the error coming from the server
    }
}

async function login(username, password) {
    return new Promise((resolve, reject) => {
        fetch(BaseURL + '/admin/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: username, password: password}),
        }).then((response) => {
            if (response.ok) {
                response.json().then((obj) => { resolve(obj); });
            } else {
                response.json().then((obj) => { reject(obj); })
                .catch(() => {
                    let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 401? "Credenziali errate" : response.status === 500? "Errore server" : response.statusText};
                    reject(err);
                });
            }
        }).catch((errObj) => {
            let err = {param: "Server", status: 500, statusText: "Cannot communicate"};
            reject(err); // connection errors
        });
    });
}

async function logout(){
    return new Promise((resolve, reject) => {
        fetch(BaseURL + '/admin/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})
        })
        .then((response) => {
            if(response.ok) resolve(null);
            else reject(null);
        })
        .catch(() => {
            let err = { param: "Server", status: 500, statusText: "Cannot communicate" };
            reject(err);
        });
    });
}

async function isAuthenticated(){
    return new Promise((resolve, reject) => {
        let url = "/admin/isAuthenticated";
        fetch(BaseURL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})
        })
        .then((response) => {
            if(response.ok){
                response.json().then((obj) => { resolve(obj); });
            } else {
                response.json().then((obj) => { reject(obj); })
                .catch(() => {
                    let err = {param: response.status === 500? "Server" : "Client", status: response.status, statusText: response.status === 500? "Errore di connessione: impossibile raggiungere il server" : response.statusText};
                    reject(err);
                });
            }
        }).catch((errObj) => {
            let err = { param: "Server", status: 500, statusText: "Cannot communicate" };
            reject(err); // An object with the error coming from the server
        });
    });
}

const ex = {
    getProjects,
    getProject,
    getAcademia,
    getPartners,
    getPartner,
    getPublications,
    getContacts,
    getHomepage,
    login,
    logout,
    isAuthenticated
};
export default ex;