import '../Css_files/OrizontalLine.css';
import React from 'react';
import line from '../Images/vertical line.jpg'

function OrizontalLine(props){
    return <>
        <div className={"orizontal-line " + props.cl}>
            <img src={line} alt="Resourse not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
        </div>
    </>;
}

export default OrizontalLine;