import '../Css_files/Project.css';

import React, {useState, useEffect, useContext} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

import OrizontalLine from './OrizontalLine.js';
import VerticalLine from './VerticalLine.js';
import {  LanguageContext } from '../Language/Language';
import API from '../API.js';


function Project(props){
    let {setLoading} = props;
    const {project} = useParams();
    const {dictionary, userLanguage} = useContext(LanguageContext);
    const [projectInfo, setProjectInfo] = useState({});
    const [canvasType, setCanvasType] = useState();
    const [allCanvas, setAllCanvas] = useState({});
    const [red, setRed] = useState(false);
    const [thumbnail, setThumbnail] = useState("");
    const [opacity, setOpacity] = useState("");
    
    useEffect(() => {
        if(project){
            setLoading(true)
            API.getProject(project)
            .then((proj) => {
                setProjectInfo(proj);
                if(proj.big_thumbnail) setThumbnail(proj.big_thumbnail);
                else setThumbnail(proj.thumbnail.replace('-ridotto', ''));
                setLoading(false);
                document.title = proj.title + " | Michele Moliteo Architetto";
            })
            .catch((err) => {
                if(err.status === 503) setRed(true);
                setLoading(false);
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [project])


    useEffect(() => {
        if(projectInfo){
            let canvas = {};
            const foto = document.getElementById('foto-images');
            if(foto){
                const f = new Viewer(foto, {
                    button: false,
                    movable: false,
                    navbar: false,
                    rotatable: false,
                    scalable: false,
                    title: false,
                    toolbar: false,
                    url(image) {
                        const f = projectInfo.photos.find((p) => {
                            if(image.src.includes(encodeURI(p.image))) return true;
                            return false;
                        });
                        if(f.big_image) return f.big_image;
                        else return image.src.replace('-ridotto', '');
                    }
                });
                canvas.foto = f;
            }
            const schizzi = document.getElementById('schizzi-images');
            if(schizzi){
                const s = new Viewer(schizzi, {
                    button: false,
                    movable: false,
                    navbar: false,
                    rotatable: false,
                    scalable: false,
                    title: false,
                    toolbar: false,
                    url(image) {
                        const f = projectInfo.sketches.find((p) => {
                            if(image.src.includes(encodeURI(p.image))) return true;
                            return false;
                        });
                        if(f.big_image) return f.big_image;
                        else return image.src.replace('-ridotto', '');
                    }
                });
                canvas.schizzi = s;
            }
            const piante = document.getElementById('piante-images');
            if(piante){
                const p = new Viewer(piante, {
                    button: false,
                    movable: false,
                    navbar: false,
                    rotatable: false,
                    scalable: false,
                    title: false,
                    toolbar: false,
                    url(image) {
                        const f = projectInfo.diagrams.find((p) => {
                            if(image.src.includes(encodeURI(p.image))) return true;
                            return false;
                        });
                        if(f.big_image) return f.big_image;
                        else return image.src.replace('-ridotto', '');
                    }
                });
                canvas.piante = p;
            }
            const modelli = document.getElementById('modelli-images');
            if(modelli){
                const m = new Viewer(modelli, {
                    button: false,
                    movable: false,
                    navbar: false,
                    rotatable: false,
                    scalable: false,
                    title: false,
                    toolbar: false,
                    url(image) {
                        const f = projectInfo.models.find((p) => {
                            if(image.src.includes(encodeURI(p.image))) return true;
                            return false;
                        });
                        if(f.big_image) return f.big_image;
                        else return image.src.replace('-ridotto', '');
                    }
                });
                canvas.modelli = m;
            }
            setAllCanvas(canvas);
        }
    }, [projectInfo]);

    useEffect(() => {
        if(canvasType){
            const canvases = document.getElementsByClassName("viewer-canvas");
            let canvas;
            for(let i = 0; i < canvases.length; i++){
                if(canvases[i] && canvases[i].parentElement && canvases[i].parentElement.classList.contains("viewer-in")){
                    canvas = canvases[i].parentElement;
                    break;
                }
            }
            if(canvas){
                const div = document.createElement("div");
                div.id="arrows-canvas";
                canvas.appendChild(div);
                let left_div = document.createElement("div");
                left_div.addEventListener("click", handleClickImagePrev);
                div.appendChild(left_div);
                let right_div = document.createElement("div");
                right_div.addEventListener("click", handleClickImageNext);
                div.appendChild(right_div);
                const xmlns = "http://www.w3.org/2000/svg";

                /*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill-rule="evenodd" d="M15.28 5.22a.75.75 0 00-1.06 0l-6.25 6.25a.75.75 0 000 1.06l6.25 6.25a.75.75 0 101.06-1.06L9.56 12l5.72-5.72a.75.75 0 000-1.06z">
                    </path>
                </svg> */

                let prev = document.createElementNS(xmlns, "svg");

                prev.setAttribute("width", "24");
                prev.setAttribute("height", "24");
                prev.setAttribute("viewBox", "0 0 24 24");
                prev.id="prev-image";
                
                const path_prev = document.createElementNS(xmlns, "path");
                path_prev.setAttribute("fillRule", "evenodd");
                path_prev.setAttribute("d", "M15.28 5.22a.75.75 0 00-1.06 0l-6.25 6.25a.75.75 0 000 1.06l6.25 6.25a.75.75 0 101.06-1.06L9.56 12l5.72-5.72a.75.75 0 000-1.06z");
                prev.appendChild(path_prev);
                left_div.appendChild(prev);



                /*
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill-rule="evenodd" d="M8.72 18.78a.75.75 0 001.06 0l6.25-6.25a.75.75 0 000-1.06L9.78 5.22a.75.75 0 00-1.06 1.06L14.44 12l-5.72 5.72a.75.75 0 000 1.06z">
                    </path>
                </svg>
                */
                let next = document.createElementNS(xmlns, "svg");

                next.setAttribute("width", "24");
                next.setAttribute("height", "24");
                next.setAttribute("viewBox", "0 0 24 24");
                next.id="next-image";
                
                const path_next = document.createElementNS(xmlns, "path");
                path_next.setAttribute("fillRule", "evenodd");
                path_next.setAttribute("d", "M8.72 18.78a.75.75 0 001.06 0l6.25-6.25a.75.75 0 000-1.06L9.78 5.22a.75.75 0 00-1.06 1.06L14.44 12l-5.72 5.72a.75.75 0 000 1.06z");
                next.appendChild(path_next);
                right_div.appendChild(next);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasType, allCanvas]);

    const handleClickImagePrev = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const canvas = allCanvas[canvasType];
        canvas.prev();
    }

    const handleClickImageNext = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const canvas = allCanvas[canvasType];
        canvas.next();
    }

    const handleClickImage = (type) => {
        setCanvasType(type);
    };

    const handleClick = (op)=>{
        setOpacity(op);
        setTimeout(() => {
            window.onscroll = () => {handleScroll();}
        }, 1000);
    }

    const handleScroll = ()=>{
        setOpacity("");
        window.onscroll = () => null;
    }

    return <>
        {red && <Redirect to='/progetti'/>}
        {projectInfo &&
        <div className="project">
            <img src={thumbnail} className="main-img" alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
            <div id="scroll-down-layout">
                <AnchorLink href="#title" id="scroll-down">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </AnchorLink>
            </div>
            <div className="title" id="title">{projectInfo.title}</div>
            
            <OrizontalLine/>

            <ProjectMenu projectInfo={projectInfo} handleClick={handleClick} dictionary={dictionary} userLanguage={userLanguage}/>
            <div className="project-layout">
                {projectInfo.text_it && userLanguage === "it" && <>
                    <div id = "testo">
                        <div className="subtitle">{dictionary.project_menu.text}</div>
                        <OrizontalLine/>
                        <div className="text">{projectInfo.text_it}</div>
                    </div>
                </>}

                {projectInfo.text_en && userLanguage === "en" && <>
                    <div id = "testo">
                        <div className="subtitle">{dictionary.project_menu.text}</div>
                        <OrizontalLine/>
                        <div className="text">{projectInfo.text_en}</div>
                    </div>
                </>}


                {projectInfo.photos && <>
                    <div id="foto">
                        <div className="subtitle">{dictionary.project_menu.photos}</div>
                        <OrizontalLine/>
                        <QuadImages images={projectInfo.photos} id="foto-images" handleClickImage={handleClickImage} type="foto" userLanguage={userLanguage} opacity={opacity}/>  
                    </div>  
                </>}

                {projectInfo.sketches && <>
                    <div id="schizzi">
                        <div className="subtitle">{dictionary.project_menu.sketches}</div>
                        <OrizontalLine/>
                        <QuadImages images={projectInfo.sketches} id="schizzi-images" handleClickImage={handleClickImage} type="schizzi" userLanguage={userLanguage} opacity={opacity}/>
                    </div>
                </>}

                {projectInfo.diagrams && <>
                    <div id="piante">
                        <div className="subtitle">{dictionary.project_menu.diagrams}</div>
                        <OrizontalLine/>
                        <QuadImages images={projectInfo.diagrams} id="piante-images" handleClickImage={handleClickImage} type="piante" userLanguage={userLanguage} opacity={opacity}/>
                    </div>
                </>}

                {projectInfo.models && <>
                    <div id="modelli">
                        <div className="subtitle">{dictionary.project_menu.models}</div>
                        <OrizontalLine/>
                        <QuadImages images={projectInfo.models} id="modelli-images" handleClickImage={handleClickImage} type="modelli" userLanguage={userLanguage} opacity={opacity}/>
                    </div>
                </>}

                

                {projectInfo.books && <>
                    <div id="book">
                        <div className="subtitle">{dictionary.project_menu.books}</div>
                        <OrizontalLine/>
                        <SixImages images={projectInfo.books} type="book" opacity={opacity}/>
                    </div>
                </>}

            </div>
        </div>
        }
    </>
}

function ProjectMenu(props){
    let {projectInfo, handleClick, dictionary, userLanguage} = props;
    const text_exists = (projectInfo.text_it && userLanguage === "it") || (projectInfo.text_en && userLanguage === "en");
    return <>
        <div className="menu">
            {text_exists && <AnchorLink href="#testo" className="item" onClick={()=>handleClick("testo")}>{dictionary.project_menu.text}</AnchorLink>}
            {text_exists && (projectInfo.photos || projectInfo.sketches || projectInfo.diagrams || projectInfo.models || projectInfo.books) &&
                <VerticalLine/>
            }
            {projectInfo.photos && <AnchorLink href="#foto" className="item"  onClick={()=>handleClick("foto")}>{dictionary.project_menu.photos}</AnchorLink>}
            {projectInfo.photos && (projectInfo.sketcges || projectInfo.diagrams || projectInfo.models || projectInfo.books) &&
                <VerticalLine/>
            }
            {projectInfo.sketches && <AnchorLink href="#schizzi" className="item" onClick={()=>handleClick("schizzi")}>{dictionary.project_menu.sketches}</AnchorLink>}
            {projectInfo.sketches && (projectInfo.diagrams || projectInfo.models || projectInfo.books) &&
                <VerticalLine/>
            }
            {projectInfo.diagrams && <AnchorLink href="#piante" className="item" onClick={()=>handleClick("piante")}>{dictionary.project_menu.diagrams}</AnchorLink>}
            {projectInfo.diagrams && (projectInfo.models || projectInfo.books) &&
                <VerticalLine/>
            }
            {projectInfo.models && <AnchorLink href="#modelli" className="item" onClick={()=>handleClick("modelli")}>{dictionary.project_menu.models}</AnchorLink>}
            {projectInfo.models && (projectInfo.books) &&
                <VerticalLine/>
            }
            {projectInfo.books && <AnchorLink href="#book" className="item" onClick={()=>handleClick("book")}>{dictionary.project_menu.books}</AnchorLink>}
        </div>
    </>;
}

function QuadImages(props){
    return <>
        <Container fluid>
            <Row id={props.id}>
                {props.images.map((image, index)=>
                    <Col key={index} xs={6} sm={4} md={3} onClick={() => props.handleClickImage(props.type)}>
                        <div className="image">
                            <div className="image-container">
                                <img src={image.image} alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                                {props.opacity !== "" && props.opacity !== props.type && <div className="opacity"></div>}
                            </div>
                            {props.userLanguage === "it" && <div className="img-title">{image.title_it}</div>}
                            {props.userLanguage === "en" && <div className="img-title">{image.title_en}</div>}
                        </div>
                    </Col>  
                )}
            </Row>
        </Container>
    </>
}

function SixImages(props){
    return <>
        <Container fluid>
            <Row>
                {props.images.map((image, index)=>
                    <Col className="image-six" key={index} xs={4} sm={3} md={3} lg={2}>
                        <a className="image" href={image.link} target="_blank" rel="noreferrer">
                            <div className="image-container">
                                <img src={image.thumbnail} alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                                {props.opacity !== "" && props.opacity !== props.type && <div className="opacity"></div>}
                            </div>
                            
                            <div className="img-title">{image.title}</div>
                        </a>
                    </Col>
                )}
            </Row>
        </Container>
    </>
}

export default Project;