import '../Css_files/Foot.css';
import {Link} from 'react-router-dom';
import React, {useContext} from 'react';

import line from '../Images/orizontal line.jpg';
import logo_big from '../Images/logo BIG.jpg';
import {  LanguageContext } from '../Language/Language';

function Foot(props){
    const {dictionary} = useContext(LanguageContext);
    return <>
        <div id='custom-foot'>
            <div className="orizontal-line">
                <img src={line} alt="Resourse not found"/>
            </div>
            
            <div className="foot-centre">
                <div className='sviluppo'>
                    <div className='da'>{dictionary.footer.developed}</div>
                    <img className='nome' src={logo_big} alt="BIG"/>
                </div>
                <a href="https://www.instagram.com/mi_architectureoffice" target="_blank" rel="noreferrer" className="item">Instagram</a>
                <a href="https://www.linkedin.com/company/mi-architecture-office" target="_blank" rel="noreferrer" className="item">Linkedin</a>
                <Link to="/contatti" className="item">{dictionary.footer.contacts}</Link>
                <div className='copyright'>
                    <span className='simbolo'>©</span><span className='nome'>All Rights Reserved</span>
                </div>
            </div>
            
        </div>
    </>
}

export default Foot;