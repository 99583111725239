import '../Css_files/VerticalLine.css';
import React from 'react';
import line from '../Images/orizontal line.jpg';

function VerticalLine(props){
    return <>
        <div className="vertical-line">
            <img src={line} alt="Resourse not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
        </div>
    </>;
}

export default VerticalLine;