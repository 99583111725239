import './App.css';
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Navig from './Components/Navig.js';
import Foot from './Components/Foot.js';
import Projects from './Components/Projects.js';
import Accademia from './Components/Accademia.js';
import Partners from './Components/Partners.js';
import Publications from './Components/Publications.js';
import Contacts from './Components/Contacts.js';
import Project from './Components/Project.js';
import Partner from './Components/Partner.js';
import Homepage from './Components/Homepage.js';
import Profile from './Components/Profile.js';
//import Login from './Components/Login.js';
import {LanguageProvider} from './Language/Language.js';

import logo_barra from './Images/logo_barra.jfif';

function App(props) {
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const layout = document.getElementsByClassName("layout-content")[0];
    const foot = document.getElementById('foot');
    const navig = document.getElementsByClassName('navig')[0];

    if(layout){
      if(loading){  //rendo tutto ciò che non è loading opaco
        layout.classList.add("hidden");
      }
      else{ //rendo tutto ciò che non è loading non opaco
        layout.classList.remove("hidden");
      }
    }

    if(foot){
      if(loading){  //rendo tutto ciò che non è loading opaco
        foot.classList.add("hidden");
      }
      else{ //rendo tutto ciò che non è loading non opaco
        foot.classList.remove("hidden");
      }
    }

    if(navig){
      if(loading){  //rendo tutto ciò che non è loading opaco
        navig.classList.add("hidden");
      }
      else{ //rendo tutto ciò che non è loading non opaco
        navig.classList.remove("hidden");
      }
    }
  }, [loading]);




  


  return (
    <LanguageProvider>
      <div className="layout">
        <Router>
          {loading && <Loading/>}
          <Switch>

          <Route path='/progetti/:project' render={(props) => {
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>

                <div className="layout-content">
                  <Project setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/progetti' render={(props) => {
              //here goes the code for /progetti route
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Projects setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/accademia' render={(props) => {
              //here goes the code for /accademia route
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Accademia setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/mi+partners/:partner' render={(props) => {
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Partner setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/mi+partners' render={(props) => {
              return <>

                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Partners setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/pubblicazioni' render={(props) => {
              //here goes the code for /pubblicazioni route
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Publications setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/profilo' render={(props) => {
              //here goes the code for /profilo route
              return <>

                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Profile/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/contatti' render={(props) => {
              //here goes the code for /contatti route
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Contacts setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/home' render={(props) => {
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Homepage setLoading={setLoading} setProfile={setProfile}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/' render={(props) => {
              //here goes the code for / route
              return <Redirect to="/home" />
            }}>
            </Route>
          </Switch>
        </Router>
      </div>
    </LanguageProvider>
  );
}

function Loading(props) {
  return <div id='loading'>
    <div className='loading-layout'>
        <div className='spinner-text'><img src={logo_barra} alt='MI'/></div>
        <div className='spinner' role='status'></div>
      </div>
  </div>;
}

export default App;

/*


      ******************** PROVE ESTERNE AL RETURN **********************************

      ******************************************* prova immagini ******************************************

  
  const [provaimg, setProvaimg] = useState();


  const dragoverenter = (e) => {
      e.stopPropagation();
      e.preventDefault();
  }

  const drop = (e) => {
    e.stopPropagation();
    e.preventDefault();
  
    const dt = e.dataTransfer;
    const files = dt.files;
  
    insertFile(files);
  }

  const onClickInsertFile = (e) => {
    let input = e.currentTarget.children[0];
    input.click();
  }

  const insertFile = (files) => {
    const file = files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      console.log(e.target.result);
    };
    if(file) reader.readAsDataURL(file);
  }

  useEffect(() => {
    if(provaimg){
      const img = document.createElement("img");
      img.classList.add("obj");
      img.src = provaimg;
      document.getElementsByClassName("layout-content")[0].appendChild(img);
    }
  }, [provaimg]);
  }



  ********************************************* prova PDF **********************************************

  const [provaPDF, setProvaPDF] = useState("");

  const base64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const insertFilePDF = (files) => {
    const file = files[0];
    if(!file) return;

    //let url = URL.createObjectURL(file);
    //setProvaPDF(url);
    //console.log(url);

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target.result.split(',')[1];
      console.log(base64);
      const blob = base64toBlob(base64, 'application/pdf');
      const url = URL.createObjectURL(blob);
      console.log(url);
      setProvaPDF(url);
    };
    if(file) reader.readAsDataURL(file);
  }

  useEffect(() => {
    if(provaPDF){
      const pdf = document.getElementById("PDF");
      pdf.src = provaPDF + "#toolbar=0&view=Fit&scrollbar=0";
      pdf.classList.remove("hidden");
      const pdf2 = document.getElementById("PDF2");
      pdf2.src = provaPDF + "#toolbar=0&view=Fit&embedded=1&page=2";
      pdf2.classList.remove("hidden");
    }
  }, [provaPDF]);


















    ****************** ROBA LEVATA PER LA PUBBLICAZIONE ***************************

                <Route path='/mi+partners' render={(props) => {
              //here goes the code for /mi+partners route
              return <>

                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <Partners setLoading={setLoading}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

                        <Route path='/admin/login' render={(props) => {
              return <>
                
                <div className="layout-content">
                  <Login profile={profile} setProfile={setProfile} setLoading={setLoading} />
                </div>

              </>
            }}>
            </Route>

            <Route path='/admin/home' render={(props) => {
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile} admin={true}/>
                
                <div className="layout-content">
                  <Homepage setLoading={setLoading} admin={true} setProfile={setProfile}/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

















            *************************** ROBA PER L'INSERIMENTO DI DATI *************************



            <Route path='/prova' render={(props) => {
              //here goes the code for / route
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <div className="insert-file" onDragEnter={(e) => dragoverenter(e)} onDragOver={(e) => dragoverenter(e)} onDrop={(e) => drop(e)} onClick={(e) => onClickInsertFile(e)}>
                    <input type="file" accept="image/*" onChange={(e) => insertFile(e.target.files)} multiple style={{display: "none"}}></input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    <div>Insert a new image</div>
                  </div>
                  <div className="insert-file" onClick={() => provaFetch()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    <div>Prova fetch</div>
                  </div>
                </div>

                <Foot/>

              </>
            }}>
            </Route>

            <Route path='/provaPDF' render={(props) => {
              //here goes the code for / route
              return <>
                
                <Navig setLoading={setLoading} setProfile={setProfile} profile={profile}/>
                
                <div className="layout-content">
                  <div className="insert-file" onDragEnter={(e) => dragoverenter(e)} onDragOver={(e) => dragoverenter(e)} onDrop={(e) => drop(e)} onClick={(e) => onClickInsertFile(e)}>
                    <input type="file" accept="application/pdf" onChange={(e) => insertFilePDF(e.target.files)} multiple style={{display: "none"}}></input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    <div>Insert a new pdf</div>
                  </div>
                  {provaPDF && <>
                  <div style={{display: "flex"}}>
                    <div style={{overflow: "hidden", overflowX: "hidden", overflowY: "hidden"}}>
                      <iframe type="application/pdf" style={{width:"43vw", height:"90vh", pointerEvents: "none", overflow: "hidden", overflowX: "hidden", overflowY: "hidden"}} allowFullScreen id="PDF" src={provaPDF} className="hidden" title="pdf"></iframe>
                    </div>
                    <div style={{overflow: "hidden", overflowX: "hidden", overflowY: "hidden"}}>
                      <iframe type="application/pdf" style={{width:"43vw", height:"90vh", pointerEvents: "none", overflow: "hidden", overflowX: "hidden", overflowY: "hidden"}} allowFullScreen id="PDF2" src={provaPDF} className="hidden" title="pdf"></iframe>
                    </div>
                  </div>
                  </>
                  }
                  <Loading/>
                </div>

                <Foot/>

              </>
            }}>
            </Route>












                  <input type="file" accept="image/*" onChange={(e) => insertFile(e)} multiple></input>


  const insertFile = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      if (!file.type.startsWith('image/')){ continue }
  
      const img = document.createElement("img");
      img.classList.add("obj");
      img.file = file;
      document.getElementsByClassName("layout-content")[0].appendChild(img); // Assuming that "preview" is the div output where the content will be displayed.
  
      const reader = new FileReader();
      reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(img);
      reader.readAsDataURL(file);
    }
  }




    const dragoverenter = (e) => {
      e.stopPropagation();
      e.preventDefault();
  }

  const drop = (e) => {
    e.stopPropagation();
    e.preventDefault();
  
    const dt = e.dataTransfer;
    const files = dt.files;
  
    insertFile(files);
  }

  const onClickInsertFile = (e) => {
    let input = e.currentTarget.children[0];
    input.click();
  }

  const insertFile = (files) => {
    const file = files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      setProvaimg(e.target.result);
    };
    if(file) reader.readAsDataURL(file);
  }



    useEffect(() => {
    if(provaimg){
      const img = document.createElement("img");
      img.classList.add("obj");
      img.src = provaimg;
      document.getElementsByClassName("layout-content")[0].appendChild(img);
    }
  }, [provaimg]);






    const [provaPDF, setProvaPDF] = useState();

      const base64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const insertFilePDF = (files) => {
    const file = files[0];
    if(!file) return;

    //let url = URL.createObjectURL(file);
    //setProvaPDF(url);
    //console.log(url);

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target.result.split(',')[1];
      console.log(base64);
      const blob = base64toBlob(base64, 'application/pdf');
      const url = URL.createObjectURL(blob);
      console.log(url);
      setProvaPDF(url);
    };
    if(file) reader.readAsDataURL(file);
  }

  useEffect(() => {
    if(provaPDF){
      const pdf = document.getElementById("PDF");
      pdf.src = provaPDF + "#toolbar=0&navpanes=0&scrollbar=0";
      pdf.classList.remove("hidden");
    }
  }, [provaPDF]);




    /*********************************** Prova Viewer ***********************************/
  /*const [viewer, setViewer] = useState();
  const [gallery, setGallery] = useState();

  useEffect(() => {
    const image = document.getElementById('image');
    console.log(image);
    const v = new Viewer(image, {
      viewed(){
        
      },
    });
    setViewer(v);

    const images = document.getElementById('images');
    console.log(images);
    const g = new Viewer(images);
    setGallery(g);
  }, []);



  <div className="insert-file" onDragEnter={(e) => dragoverenter(e)} onDragOver={(e) => dragoverenter(e)} onDrop={(e) => drop(e)} onClick={(e) => onClickInsertFile(e)}>
                    <input type="file" accept="image/*" onChange={(e) => insertFile(e.target.files)} multiple style={{display: "none"}}></input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    <div>Insert a new image</div>
                  </div>
                   */