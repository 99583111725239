import '../App.css';

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SixImages(props){
    return <>
        <Container fluid>
            <Row>
                {props.images.map((image, index)=>
                    <Col className="image-six" key={index} xs={4} sm={3} md={3} lg={2}>
                        <a className="image" href={image.link} target="_blank" rel="noreferrer">
                            <img src={image.thumbnail} alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                            <div className="img-title">{image.title}</div>
                        </a>
                    </Col>
                )}
            </Row>
        </Container>
    </>
}

export default SixImages;