import '../Css_files/Homepage.css';

import React, { useEffect, useState } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Redirect } from 'react-router-dom';
import SwiperCore, {Navigation, Pagination, EffectFade, Autoplay} from 'swiper';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'swiper/swiper-bundle.css';

import ThrProjects from './ThrProjects.js';
import API from '../API.js';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function Homepage(props){
    let {setProfile, setLoading} = props;

    const [projects, setProjects] = useState([]);
    const [mainImages, setMainImages] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        props.setLoading(true);
        API.getHomepage()
        .then((home) => {
            setProjects(home.projects);
            setMainImages(home.thumbnails);
            setLoading(false);
            API.isAuthenticated()
            .then((user) => {
                setProfile(user);
                setAuthenticated(true);
                setLoading(false);
            }).catch((errorObj) => {
                setProfile({});
                setLoading(false);
            });
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
            API.isAuthenticated()
            .then((user) => {
                setProfile(user);
                setLoading(false);
            }).catch((errorObj) => {
                setProfile({});
                setLoading(false);
                setAuthenticated(false);
            });
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = "Michele Moliteo Architetto";
      }, []);


    return <>
        {!authenticated && <Redirect to='/home'/>}
        {mainImages.length > 0 && <Gallery mainImages={mainImages}/>}
        <div id="projects">
            {projects && <ThrProjects projects = {projects} handleClick={props.handleClick}/> }
        </div>
    </>
}

function Gallery(props){
    let {mainImages} = props;
    return <>
    <Swiper id="main"
        counter={mainImages.length}
        tag="section"
        wrapperTag="span"
        effect="fade"
        loop="true"
        autoplay={{
            delay: 5000,
            disableOnInteraction: false,
        }}
        navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }}
        pagination={{
            type: "custom"
        }}
        spaceBetween={0}
        slidesPerView={1}>

        {mainImages.map((image, i) => <SwiperSlide key={i} tag="li"><img className="img-responsive-width" src={image.image} style={{ listStyle: 'none' }} alt="Resource not found" onContextMenu={(e) => e.preventDefault()}/></SwiperSlide>)}

    </Swiper>
    <div id="scroll-down-layout">
        <AnchorLink href="#projects" id="scroll-down">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
        </AnchorLink>
    </div>
    
    
    </>
}

export default Homepage;
