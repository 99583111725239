import '../Css_files/Contacts.css';

import React, {useState, useEffect, useContext} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import OrizontalLine from './OrizontalLine.js';
import {  LanguageContext } from '../Language/Language';

import API from '../API.js';

function Contacts(props){
    let {setLoading} = props;
    const {dictionary} = useContext(LanguageContext);
    const [Contacts, setContacts] = useState();

    useEffect(() => {
        setLoading(true);
        API.getContacts()
        .then((cont) => {
            setContacts(cont);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(dictionary.footer.contacts) document.title = dictionary.footer.contacts + " | Michele Moliteo Architetto";
      }, [dictionary]);

    return <>
        {Contacts && <>
            <img src={Contacts.thumbnail} className="main-img" alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
            <div id="scroll-down-layout">
                <AnchorLink href="#contacts" id="scroll-down">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </AnchorLink>
            </div>

            <OrizontalLine/>

            <div id="address">
                {Contacts.addresses.map((c, index) => <div key={index}><span>{c.city}</span> | {c.address}</div>)}
            </div>

            <div id="contacts">

                <div className="con-layout">
                    <div className="name">{dictionary.contacts.number}</div>
                    <div className="textes">
                        {Contacts.numbers.map((num, index) => <a href={"tel:"+num} key={index}>{num}</a>)}
                    </div>
                </div>

                <div className="con-layout">
                    <div className="name">{dictionary.contacts.mail}</div>
                    <div className="textes">
                        {Contacts.mails.map((ma, index) => <a href={"mailto:"+ma} key={index}>{ma}</a>)}
                    </div>
                </div>

                <div className="con-layout">
                    <div className="name">{dictionary.contacts.vat}</div>
                    <div className="textes">
                        <div>05805470878</div>
                    </div>
                </div>

            </div>
        </>
        }
    </>
}

export default Contacts;