import '../App.css';

import React, {useState, useEffect, useContext} from 'react';

import ThrPartners from './ThrPartners.js';
import {  LanguageContext } from '../Language/Language';
import API from '../API.js';

function Partners(props){
    let { handleClick, setLoading } = props;
    const [partners, setPartners] = useState();
    const {dictionary} = useContext(LanguageContext);

    useEffect(() => {
        setLoading(true);
        API.getPartners()
        .then((parts) => {
            setPartners(parts);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(dictionary.main_menu["mi+partners"]) document.title = dictionary.main_menu["mi+partners"] + " | Michele Moliteo Architetto";
      }, [dictionary]);

    return <>
        <ThrPartners partners={partners} handleClick={handleClick}/>
    </>
}

export default Partners;