import '../App.css';

import React, {useState, useEffect, useContext} from 'react';

import ThrProjects from './ThrProjects.js';
import {  LanguageContext } from '../Language/Language';
import API from '../API.js';

function Projects(props){
    let { setLoading } = props;
    const [projects, setProjects] = useState();
    const {dictionary} = useContext(LanguageContext);

    useEffect(() => {
        setLoading(true)
        API.getProjects()
        .then((projs) => {
            setProjects(projs);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        if(dictionary.main_menu.projects) document.title = dictionary.main_menu.projects + " | Michele Moliteo Architetto";
      }, [dictionary]);

    return <>
        <ThrProjects projects={projects}/>
    </>
}

export default Projects;