import '../App.css';

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function ThrPartners(props){
    return <>
        <Container fluid>
            <Row>
              {props.partners &&
                props.partners.map((part)=>
                    <Col key={part.idPa} xs={6} sm={6} md={4}>
                      <Link id={part.idPa} className="cont" to={"/mi+partners/" + part.idPa}>
                          <img src={part.thumbnail} className="project-image" alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                          <div className="project-title">{part.title}</div>
                      </Link>
                    </Col>  
                )}
            </Row>
        </Container>
    </>
  }

export default ThrPartners;