import '../App.css';

import React, {useState, useEffect, useContext} from 'react';

import ThrProjects from './ThrProjects.js';
import {  LanguageContext } from '../Language/Language';
import API from '../API.js';

function Accademia(props){
    let { handleClick, setLoading } = props;
    const [projects, setProjects] = useState();
    const {dictionary} = useContext(LanguageContext);

    useEffect(() => {
        setLoading(true);
        API.getAcademia()
        .then((projs) => {
            setProjects(projs);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(dictionary.main_menu.academia) document.title = dictionary.main_menu.academia + " | Michele Moliteo Architetto";
      }, [dictionary]);


    return <>
        <ThrProjects projects={projects} handleClick={handleClick}/>
    </>
}

export default Accademia;