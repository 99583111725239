import it from './it.json';
import en from  './en.json';
import React, { createContext, useState } from 'react';

//initial language
const initialLanguage = "en";

export const dictionaryList = {en, it};

export const languageOptions = {
    en: "EN",
    it: "IT"
};

export const LanguageContext = createContext({
    userLanguage: initialLanguage,
    dictionary: dictionaryList[initialLanguage]
});

export function LanguageProvider({ children }) {
    const defaultLanguage = window.localStorage.getItem('rcml-lang');
    const [userLanguage, setUserLanguage] = useState(defaultLanguage || initialLanguage);
  
    const provider = {
      userLanguage,
      dictionary: dictionaryList[userLanguage],
      //call this function when you want to change user language;
      userLanguageChange: selected => {
        const newLanguage = languageOptions[selected] ? selected : initialLanguage
        setUserLanguage(newLanguage);
        window.localStorage.setItem('rcml-lang', newLanguage);
      }
    };
  
    return (
      <LanguageContext.Provider value={provider}>
        {children}
      </LanguageContext.Provider>
    );
  };