import '../Css_files/Navig.css';

import {Link} from 'react-router-dom';
import React, { useContext } from 'react';
import { pushRotate as Menu } from 'react-burger-menu'

import VerticalLine from './VerticalLine'
import {  LanguageContext } from '../Language/Language';
import API from '../API.js';

import logo from '../Images/logo.jpg'

function Navig(props){
    let {profile, setProfile, setLoading, admin} = props;
    const {userLanguageChange, dictionary} = useContext(LanguageContext);

    const ChangeLanguage = (lang) => {
        userLanguageChange(lang);
    };

    const logout = function() {
        setLoading(true);
        API.logout()
        .then(() => {
            setProfile({});
            setLoading(false);
        })
        .catch(() => setLoading(false));
    };

    const onClickBurger = function(s){
        const menu = document.getElementById('burger-menu');
        if(!s.isOpen){
            menu.hidden = true;
        }
        else{
            menu.hidden = false;
        }
    }

    const closeBurger = function(){
        let element = document.getElementsByClassName("bm-overlay")[0];
        element.click();
    }

    return <>
        <div className="navig">
            <div className="nav1">
                {profile.username && <div className="admin-container">
                    <div className="item" onClick={() => logout()}>Logout</div>
                    {admin && <Link to='/home' className="item">Vai al sito</Link>}
                    {!admin && <Link to='/admin/home' className="item">Vai a gestione</Link>}
                </div>
                }
                <Link to='/'>
                    <img className="logo" src={logo} alt='MI Architecture Office' onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                </Link>
            </div>
            <div className="nav2">
                <div className="menu1">
                    {admin && profile.username && <Link to='/admin/progetti' className="item">{dictionary.main_menu.projects}</Link>}
                    {!admin && <Link to='/progetti' className="item">{dictionary.main_menu.projects}</Link>}
                    <VerticalLine/>
                    {admin && profile.username && <Link to='/admin/accademia' className="item">{dictionary.main_menu.academia}</Link>}
                    {!admin && <Link to='/accademia' className="item">{dictionary.main_menu.academia}</Link>}
                    <VerticalLine/>
                    {admin && profile.username && <Link to='/admin/mi+partners' className="item">{dictionary.main_menu['mi+partners']}</Link>}
                    {!admin && <Link to='/mi+partners' className="item">{dictionary.main_menu['mi+partners']}</Link>}
                    <VerticalLine/>
                    {admin && profile.username && <Link to='/admin/pubblicazioni' className="item">{dictionary.main_menu.books}</Link>}
                    {!admin && <Link to='/pubblicazioni' className="item">{dictionary.main_menu.books}</Link>}
                    <VerticalLine/>
                    {admin && profile.username && <Link to='/admin/profilo' className="item">{dictionary.main_menu.profile}</Link>}
                    {!admin && <Link to='/profilo' className="item">{dictionary.main_menu.profile}</Link>}
                </div>
                <div className="menu2">
                    <div className="item" onClick={() => ChangeLanguage("it")}>IT</div>
                    <VerticalLine/>
                    <div className="item" onClick={() => ChangeLanguage("en")}>EN</div>
                </div>
            </div>
        </div>
        <div id="burger-menu-container">
            <Menu
                id={"burger-menu"}
                noTransition
                onStateChange={(s) => onClickBurger(s)}
                burgerButtonClassName={"bm-burger-button"}
                menuClassName={"bm-menu-wrap"}
                width={120}
                right
                styles={{
                    bmMenuWrap: {
                        position: 'sticky',
                        textAlign: 'end',
                        left: '100%',
                        marginBottom: '30px'
                    },
                    bmOverlay: {
                        position: 'static',
                        background: 'white'
                    },
                    bmBurgerButton: {
                        position: 'absolute',
                        width: '32px',
                        height: '32px',
                        right: '10vw',
                        top: '25px',
                        outline: 'none'
                    },
                    bmCrossButton: {

                    }
                }}
                customBurgerIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                  </svg>}
                customCrossIcon={false}
                  >
                    {admin && profile.username && <Link id="progetti" className="burger-item" to="/admin/progetti" onClick={() => closeBurger()}>{dictionary.main_menu.projects}</Link>}
                    {!admin && <Link id="progetti" className="burger-item" to="/progetti" onClick={() => closeBurger()}>{dictionary.main_menu.projects}</Link>}
                    
                    {admin && profile.username && <Link id="academia" className="burger-item" to="/admin/accademia" onClick={() => closeBurger()}>{dictionary.main_menu.academia}</Link>}
                    {!admin && <Link id="academia" className="burger-item" to="/accademia" onClick={() => closeBurger()}>{dictionary.main_menu.academia}</Link>}
                    
                    {admin && profile.username && <Link id="mi+partners" className="burger-item" to="/admin/mi+partners" onClick={() => closeBurger()}>{dictionary.main_menu["mi+partners"]}</Link>}
                    {!admin && <Link id="mi+partners" className="burger-item" to="/mi+partners" onClick={() => closeBurger()}>{dictionary.main_menu["mi+partners"]}</Link>}

                    {admin && profile.username && <Link id="pubblicazioni" className="itburger-itemem" to="/admin/pubblicazioni" onClick={() => closeBurger()}>{dictionary.main_menu.books}</Link>}
                    {!admin && <Link id="pubblicazioni" className="burger-item" to="/pubblicazioni" onClick={() => closeBurger()}>{dictionary.main_menu.books}</Link>}
                    
                    {admin && profile.username && <Link id="profilo" className="burger-item" to="/admin/profilo" onClick={() => closeBurger()}>{dictionary.main_menu.profile}</Link>}
                    {!admin && <Link id="profilo" className="burger-item" to="/profilo" onClick={() => closeBurger()}>{dictionary.main_menu.profile}</Link>}


                    <div className="burger-little-item" onClick={() => {ChangeLanguage("it"); closeBurger()}} href="">IT</div>
                    <div className="burger-little-item" onClick={() => {ChangeLanguage("en"); closeBurger()}} href="">EN</div>
            </Menu>
        </div>
    </>;
}

export default Navig;


/*



                    {admin && profile.username && <Link to='/admin/mi+partners' className="item">{dictionary.main_menu['mi+partners']}</Link>}
                    {!admin && <Link to='/mi+partners' className="item">{dictionary.main_menu['mi+partners']}</Link>}
                    <VerticalLine/>









*/