import '../App.css';

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function ThrProjects(props){
    return <>
        <Container fluid>
            <Row>
            {props.projects &&
                props.projects.map((proj)=>
                    <Col key={proj.idP} xs={6} sm={6} md={4}>
                        {proj.type[0] !== "I" &&
                            <Link id={proj.idP} className="cont" to={"/progetti/"+proj.idP}>
                                <img src={proj.thumbnail} className="project-image" alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                                <div className="project-title">{proj.title}</div>
                            </Link>
                        }
                        {proj.type[0] === "I" &&
                            <div id={proj.idP} className="cont">
                                <img src={proj.thumbnail} className="project-image" alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                                <div className="project-title">{proj.title}</div>
                            </div>
                        }
                    </Col>  
                )}
            </Row>
        </Container>
    </>
}

export default ThrProjects;