import '../Css_files/Profile.css';

import React, {useEffect, useContext} from 'react';

import {  LanguageContext } from '../Language/Language';
import profileImg from '../Images/immagine michele moliteo.jpg';

function Profile(props){
    let {dictionary} = useContext(LanguageContext);

    useEffect(() => {
        if(dictionary.main_menu.profile) document.title = dictionary.main_menu.profile + " | Michele Moliteo Architetto";
      }, [dictionary]);

    return <>
        <div id="profile-layout">
            <img src={profileImg} alt="Profile not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}></img>
            <div>
                {dictionary.profile.text}
                <br></br>
                <br></br>
                {dictionary.contacts.vat}: 05805470878
            </div>
        </div>
    </>


}

export default Profile;