import '../Css_files/Project.css';

import React, {useState, useEffect, useContext} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import OrizontalLine from './OrizontalLine.js';
import ThrProjects from './ThrProjects.js';
import API from '../API.js';
import {  LanguageContext } from '../Language/Language';

function Partner(props){
    let {handleClickSetProject, setLoading} = props;
    const {userLanguage} = useContext(LanguageContext);
    const {partner} = useParams();
    const [projects, setProjects] = useState();
    const [partnerInfo, setPartnerInfo] = useState();
    const [red, setRed] = useState(false);
    const [thumbnail, setThumbnail] = useState("");

    useEffect(() => {
        if(partner){
            setLoading(true)
            API.getPartner(partner)
            .then((part) => {
                setPartnerInfo(part.partner);
                setProjects(part.projects);
                if(part.partner.big_thumbnail) setThumbnail(part.partner.big_thumbnail);
                else setThumbnail(part.partner.thumbnail.replace('-ridotto', ''));
                setLoading(false);
                document.title = part.partner.title + " | Michele Moliteo Architetto";
            })
            .catch((err) => {
                if(err.status === 503) setRed(true);
                setLoading(false);
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [partner]);

    return <>
        {red && <Redirect to='/mi+partners'/>}
        {partnerInfo && 
            <div className="project">
                <img src={thumbnail} className="main-img" alt="Resource not found" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}/>
                <div id="scroll-down-layout">
                    <AnchorLink href="#title" id="scroll-down">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </AnchorLink>
                </div>
                <div className="title" id="title">{partnerInfo.title}</div>

                {partnerInfo.text_it && userLanguage === "it" && <>
                    <OrizontalLine cl="orizontal-line-partner"/>
                    <div id = "testo">
                        <div className="text">{partnerInfo.text_it}</div>
                    </div>
                </>}

                {partnerInfo.text_en && userLanguage === "en" && <>
                    <OrizontalLine cl="orizontal-line-partner"/>
                    <div id = "testo">
                        <div className="text">{partnerInfo.text_en}</div>
                    </div>
                </>}

                {projects && projects.length > 0 && <OrizontalLine cl="orizontal-line-partner"/>}

                {projects &&
                    <ThrProjects projects={projects} handleClick={handleClickSetProject}/>
                }



            </div>
        }
    </>
}



export default Partner;