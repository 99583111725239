import '../App.css';
import '../Css_files/Publications.css';

import React, {useState, useEffect, useContext} from 'react';

import SixImages from './SixImages.js';
import {  LanguageContext } from '../Language/Language';
import API from '../API.js';

function Publications(props){
    let { setLoading } = props;
    const [publications, setPublications] = useState();
    const {dictionary} = useContext(LanguageContext);

    useEffect(() => {
        setLoading(true)
        API.getPublications()
        .then((pubs) => {
            setPublications(pubs);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        if(dictionary.main_menu.books) document.title = dictionary.main_menu.books + " | Michele Moliteo Architetto";
      }, [dictionary]);

    return <>
        {publications &&
            <div className="publications-layout">
                <SixImages images={publications}/>
            </div>
        }
    </>
}

export default Publications;